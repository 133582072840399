import "../styles/events/themes/earth/theme.scss";

import $ from "jquery";
import "../controllers/theme_controllers";

import "../src/plugins/Tabby";
import Rails from "@rails/ujs";
import { initMap } from "../src/plugins/Gmap";
import "../src/plugins/Filestack";

import { showCountdowns } from "../src/custom/events/countdown_clock";

import "../src/custom/components/Accordion";
import { initStickyEvents } from "../src/custom/components/Sticky";

import "../src/custom/events/Menu";

// eslint-disable-next-line no-multi-assign
window.$ = window.JQuery = window.jQuery = $;
// eslint-disable-next-line import/no-unresolved, import/extensions
require("semantic/semantic.min.js");

// eslint-disable-next-line no-underscore-dangle
if (!window._rails_loaded) {
  Rails.start();
}

// Supports component names relative to this directory:
const componentContext = require.context(
  "components",
  true,
  /(Registration|Common)\/.*/,
);
const ReactRailsUJS = require("react_ujs");

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentContext);

require("jquery-countdown");

const init = () => {
  initMap("event_location_map"); // Main location map
  initMap("map"); // map for hotel (if provided)
  initStickyEvents(".sticky");
  showCountdowns();
};

document.addEventListener("DOMContentLoaded", init);
