import Stickyfill from 'stickyfilljs';

export const initStickyEvents = (container) => {
  // IE Fallback
  const elements = document.querySelectorAll(container);
  Stickyfill.add(elements);

  // to check when element get's position sticky
  const observer = new IntersectionObserver(((entries) => {
  // no intersection
    if (entries[0].intersectionRatio === 0) {
      document.querySelector(container).classList.add('fixed');
    } else if (entries[0].intersectionRatio === 1) {
      document.querySelector(container).classList.remove('fixed');
    }
  }), {
    threshold: [0, 1],
  });

  const stickyElem = document.querySelector('.sticky-top');
  if (stickyElem) {
    observer.observe(stickyElem);
  }
};
