function initMenuScript() {
  const mobileMenuBtns = document.querySelectorAll('[data-show-menu]');
  const sideMenuLinks = document.getElementsByClassName('side_menu__item');

  const toggleClass = (elem, className) => (elem.classList.contains(className)
    ? elem.classList.remove(className) : elem.classList.add(className));

  const toggleMenu = () => {
    const menu = document.querySelector('.side_menu');
    const stickyHeader = document.querySelector('header.tw-sticky');
    if (menu && stickyHeader) {
      menu.style.marginTop = `${stickyHeader.clientHeight}px`;
      menu.style.setProperty('--headMargin', `${stickyHeader.clientHeight}px`);
      toggleClass(menu, 'show');
    }
    mobileMenuBtns.forEach((btn) => toggleClass(btn, 'hidden'));
  };

  const addMenuListeners = (elems) => {
    for (let i = 0; i < elems.length; i += 1) {
      elems[i].addEventListener('click', () => toggleMenu());
    }
  };

  addMenuListeners(mobileMenuBtns);
  addMenuListeners(sideMenuLinks);
}

const init = () => {
  if (document.querySelectorAll('[data-show-menu]')) {
    initMenuScript();
  }
};

document.addEventListener('DOMContentLoaded', init);

